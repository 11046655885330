Mosaic.addPlugins([require('/var/www/html/app/design/frontend/PlugAndSell2/pwa/packages/opengraph-ts/src/plugin/MetaStore.plugin.ts')]);
import { Dispatch } from 'redux';

import { updateMeta } from 'Store/Meta/Meta.action';
import { appendWithStoreCode } from 'Util/Url';

import { Category, PageMeta, ProductMeta } from './Meta.type';

/**
 * Meta Dispatcher
 * @class MetaDispatcher
 * @namespace PlugAndSell2/Store/Meta/Dispatcher */
export class MetaDispatcher {
    /**
     * Set meta for category
     * @param {Object} category
     * @param {Function} dispatch
     * @memberof MetaDispatcher
     */
    updateWithCategory(category: Category, dispatch: Dispatch): void {
        const meta = this._getCategoryMeta(category);

        dispatch(updateMeta(meta));
    }

    /**
     * Set meta for product
     * @param {Object} product
     * @param {Function} dispatch
     * @memberof MetaDispatcher
     */
    updateWithProduct(product: ProductMeta, dispatch: Dispatch): void {
        const meta = this._getProductMeta(product);

        dispatch(updateMeta(meta));
    }

    /**
     * Get meta for product
     * @param {Object} product
     * @return {Object} Meta object
     * @memberof MetaDispatcher
     */
    _getProductMeta(product: ProductMeta): Partial<PageMeta> {
        const { name, meta_title, meta_keyword, canonical_url = '', meta_description } = product;

        return {
            description: meta_description,
            keywords: meta_keyword,
            title: meta_title || name,
            canonical_url: `${window.location.origin}${appendWithStoreCode(canonical_url)}`,
        };
    }

    /**
     * Get meta for category
     * @param {Object} category
     * @return {Object} Meta object
     * @memberof MetaDispatcher
     */
    _getCategoryMeta(category: Category): Partial<PageMeta> {
        const { description, name, canonical_url = '', meta_title, meta_keywords, meta_description, meta_robots = 'follow, index' } = category;

        return {
            description: meta_description || description,
            title: meta_title || name,
            keywords: meta_keywords,
            canonical_url: `${window.location.origin}${appendWithStoreCode(canonical_url)}`,
            robots: meta_robots,
        };
    }
}

export default new MetaDispatcher();
